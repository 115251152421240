import React from 'react';
import { Modal, Button, Input } from 'antd';

class AdminSettings extends React.Component {
	constructor() {
		super();
		const storedValue = JSON.parse(
			sessionStorage.getItem('secretHeaders') || '{}',
		);

		const secret = storedValue ? storedValue.secret : '';
		const mail = storedValue ? storedValue.mail : '';
		this.state = {
			modal: false,
			secret,
			mail,
		};
	}

	handleModal = () => {
		this.setState(prevState => ({
			modal: !prevState.modal,
		}));
	};

	handleInput = e => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	handleSave = () => {
		const { secret, mail } = this.state;

		sessionStorage.setItem(
			'secretHeaders',
			JSON.stringify({ secret, mail }),
		);
		this.handleModal();
		window.location.reload();
	};

	render() {
		const { modal, secret, mail } = this.state;
		return (
			<React.Fragment>
				<Button
					onClick={this.handleModal}
					style={{ position: 'fixed', bottom: 15, right: 70 }}
					shape="circle"
					size="large"
					icon="setting"
				/>
				<Modal
					visible={modal}
					title="Add secret key and email id"
					okText="Save"
					onCancel={this.handleModal}
					onOk={this.handleSave}
				>
					<Input.TextArea
						placeholder="Secret Key"
						name="secret"
						value={secret}
						onChange={this.handleInput}
						style={{ marginBottom: 20 }}
					/>
					<Input
						type="mail"
						placeholder="Email"
						name="mail"
						value={mail}
						onChange={this.handleInput}
					/>
				</Modal>
			</React.Fragment>
		);
	}
}

export default AdminSettings;
